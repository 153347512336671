import React from 'react';
import './App.scss';
import Routes from './Router/Routes';
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history';


ReactGA.initialize('UA-197103415-1');
ReactGA.pageview(window.location.pathname);
const history: any = createBrowserHistory();
history.listen(() => {
    ReactGA.pageview(window.location.pathname);
    console.log(window.location.pathname)
});


function App() {
  return (
    <Routes/>
  );
}

export default App;
