import React from "react";
import { Card, Container } from "react-bootstrap";
import './Home.scss';

const Error = () => {

    return (
        <>
            <Card style={{
                padding: '20px'
            }}>
                <Container>
                    <h2 style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        marginTop: '10px',
                        color: 'red'
                    }}>Ha ocurrido un error inesperado.</h2>
                </Container>
            </Card>
        </>
    );
}

export default Error;