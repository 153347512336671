import React, { useRef, useState } from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import './Home.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router";
import NoteService from "../../Service/NoteService";

interface FormInput {
    value: string,
    valid: boolean,
    validator: RegExp
}

interface NewNoteForm {
    title: FormInput,
    description: FormInput
}

const NewNote = () => {

    const [f_error, set_f_error] = useState(false);
    const history = useHistory();
    let { pid } = useParams() as any;
    let titleInput = useRef(null as HTMLInputElement);
    let descriptionInput = useRef(null as HTMLTextAreaElement);

    const [startDate, setStartDate] = useState(new Date());

    const [form, setForm] = useState({
        title: {
            value: '',
            valid: true,
            validator: /.{3,}/
        },
        description: {
            value: '',
            valid: true,
            validator: /.{0,}/
        }
    } as NewNoteForm);

    const onChange = (field: keyof NewNoteForm) => {
        return (e: any) => {
            var value = e.target.value;
            var valid = false;
            var validator = (form[field] as FormInput).validator;
            if ((form[field] as any).validator) {
                if ((form[field] as FormInput).validator.test(value)) {
                    valid = true;
                } else {
                    valid = false;
                }
            } else {
                if (value !== "") {
                    valid = true;
                } else {
                    valid = false;
                }
            }
            setForm({
                ...form, [field]: {
                    validator: validator,
                    valid: valid,
                    value: value
                }
            });
        }
    }

    const newN = () => {
        set_f_error(false);
        if(form.title.valid
            && form.title.value.trim() !== '') {
                
                NoteService.createNote(pid, form.title.value, form.description.value, false, startDate)
                .then(() => {
                    history.replace(`/project/${pid}`);
                })
                .catch(() => {
                    history.replace('/error');
                })

        } else {
            set_f_error(true)
        }
    };

    return (
        <>
            <Card style={{
                padding: '20px'
            }}>
                <Container>
                    <h2 style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        marginTop: '10px'
                    }}>Nueva Nota</h2>
                    {f_error && <h3 className="abs-auth-error">Rellena todos los campos para continuar.</h3>}

                    <Form>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Titulo</Form.Label>
                            <Form.Control type="text" 
                            ref={titleInput} 
                            className={ `abs-auth-control ${form.title.valid ? '' : 'invalid-field'}` }
                            onChange={onChange("title")}/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control as="textarea" rows={3} 
                            ref={descriptionInput} 
                            className={ `abs-auth-control ${form.description.valid ? '' : 'invalid-field'}` }
                            onChange={onChange("description")}/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Fecha</Form.Label><br></br>
                            <DatePicker
                             selected={startDate} onChange={date => {if(date instanceof Date) { setStartDate(date) }}} />
                        </Form.Group>
                        <Button className="abs-auth-button" style={{
                            marginBottom: '10px'
                        }}
                        
                        onClick={newN}>
                            Añadir
                        </Button>
                    </Form>
                </Container>
            </Card>
        </>
    );
}

export default NewNote;