import axios from "axios";
import { apiURL } from "../env";
import Note from "../Models/note";
import AuthService from "./AuthService";

class NoteService {

    getNote(id: number) : Promise<Note> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.get(apiURL + `/note/${id}`, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(note => {
                    var p = note.data as Note;
                    resolve(p);
                })
                .catch(() => {
                    reject();
                })
            })
        });
    }

    updateStatus(id:number, status:boolean) : Promise<void> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.put(apiURL + `/note/${id}/status`,{
                    status: status
                }, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(() => {
                    resolve()
                })
                .catch((e) => {
                    reject();
                })
            })
        });
    }

    createNote(pid: number, title: string, text: string, status: boolean, date: Date) : Promise<void> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.post(apiURL + `/note`, {
                    project: pid,
                    title: title,
                    text: text,
                    status: status,
                    date: date
                }, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
            })
        });
    }

    updateNote(id: number, title: string, text: string, status: boolean, date: Date) : Promise<void> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.post(apiURL + `/note`, {
                    id: id,
                    title: title,
                    text: text,
                    status: status,
                    date: date
                }, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
            })
        });
    }

    deleteNote(id: number) : Promise<void> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.delete(apiURL + `/note/${id}`, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
            })
        });
    }
    
}

export default new NoteService();