import React, { useEffect, useState } from "react";
import { Card, CardColumns } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Home.scss';
import { faCheckCircle, faCircle, faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useHistory, useParams } from "react-router";
import Note from "../../Models/note";
import ProjectService from "../../Service/ProjectService";
import NoteService from "../../Service/NoteService";
import moment from "moment";

const Notes = () => {

    moment.locale('es');
    const history = useHistory();
    const [notes, setNotes] = useState([] as Note[]);
    let { pid } = useParams() as any;

    useEffect(() => {
        ProjectService.getProjectNotes(pid)
        .then(nt => {
            setNotes(nt);
        })
        .catch(() => {
            history.replace('/error');
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toEditPage = (id: number) => {
        return (e:any) => {
            e.stopPropagation()
            history.push(`/project/${pid}/edit-note/${id}`);
        }
    }

    const updateStatus = (id: number, status: boolean) => {
        return (e: any) => {
            e.stopPropagation();
            NoteService.updateStatus(id, status)
            .then(() => {
                var nt = [] as Note[];
                for(var i = 0; i < notes.length; i++) {
                    if(notes[i].id !== id) {
                        nt.push(notes[i]);
                    } else {
                        notes[i].done = status;
                        nt.push(notes[i]);
                    }
                }
                setNotes(nt);
            })
        }
    }

    const toDelete = (id: number) => {
        return (e: any) => {
            e.stopPropagation()
            NoteService.deleteNote(id)
            .then(() => {
                var nt = [] as Note[];
                for(var i = 0; i < notes.length; i++) {
                    if(notes[i].id !== id) {
                        nt.push(notes[i]);
                    }
                }
                setNotes(nt);
            })
            .catch(() => {
                history.replace('/error');
            })
        }
    }

    const newPr = (e: any) => {
        history.push(`/project/${pid}/new-note`);
        e.stopPropagation()
    }

    return (
        <>
            <CardColumns>

                <Card className="card-hover new-project" onClick={newPr}>
                    <Card.Body>
                        <Card.Title style={{ textAlign: 'center' }}>+ Nueva Nota<br /></Card.Title>
                    </Card.Body>
                </Card>
                {
                    notes.map(n => {
                        return (
                            <Card className="card-hover-no-click" key={n.id}>
                                <Card.Body style={{ textAlign: 'center' }}>
                                    <Card.Title className={`${n.done ? 'striked' : ''}`}>{n.title}</Card.Title>
                                    <Card.Subtitle className={`mb-2 text-muted ${n.done ? 'striked' : ''}`}>{moment(n.date).format('DD/MM/yyyy')}</Card.Subtitle>
                                    <Card.Text className={`${n.done ? 'striked' : ''}`}>{n.text}</Card.Text>
                                    {
                                        n.done && 
                                        <FontAwesomeIcon icon={faCheckCircle} className="icon" onClick={updateStatus(n.id, !n.done)}/>
                                    }
                                    {
                                        !n.done &&
                                        <FontAwesomeIcon icon={faCircle} className="icon" onClick={updateStatus(n.id, !n.done)}/>
                                    }
                                    <FontAwesomeIcon icon={faEdit} className="icon" onClick={toEditPage(n.id)}/>
                                    <FontAwesomeIcon icon={faTrashAlt} className="icon" onClick={toDelete(n.id)}/>
                                </Card.Body>
                            </Card>
                        );
                    })
                }

                

            </CardColumns>
        </>
    );
}

export default Notes;