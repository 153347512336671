import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Login, Signup, Logout } from "../Components/Auth";

const AuthRoutes = () => {
    return (
        <Switch>
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/signup" component={Signup} />
            <Route path="/auth/logout" component={Logout} />
            <Redirect path="auth" to="/auth/login" />
            <Redirect path="" to="/auth/login" />
        </Switch>
    );
}

export default AuthRoutes;
