import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import HomeRoutes from "../../Router/HomeRoutes";
import AuthService from "../../Service/AuthService";
import './Home.scss';

const Home = () => {

    const history = useHistory();

    if(!AuthService.isloggedin()) {
        history.push('/auth/login');
    }

    const logout = () => {
        history.push('/auth/logout');
    }

    return (
        <>
            <div className="abs-auth-background main-container">
                <Container>
                    <Row className="header-row">
                        <Col xs={9}>
                            <Link to="/">
                                <h3 className="appname">TodoApp</h3>
                            </Link>
                        </Col>
                        <Col xs={3}>
                            <Button className="abs-auth-button in-app-btn align-right" onClick={logout}>
                                Salir
                            </Button>
                        </Col>
                    </Row>

                    <HomeRoutes></HomeRoutes>

                </Container>
            </div>
        </>
    );
}

export default Home;