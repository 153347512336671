import React from "react";
import AuthRoutes from "../../Router/AuthRoutes";
import './AuthShell.scss';

const AuthShell = () => {
    return (
        <>
            <div className="abs-auth-background">
                <AuthRoutes/>
            </div>
        </>
    );
}

export default AuthShell;
