import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import AuthService from "../../Service/AuthService";
import './AuthShell.scss';

interface FormInput {
    value: string,
    valid: boolean,
    validator: RegExp
}

interface LoginForm {
    email: FormInput,
    password: FormInput
}

const Login = () => {

    const [a_error, set_a_error] = useState(false);
    const [f_error, set_f_error] = useState(false);
    const history = useHistory();

    const [form, setForm] = useState({
        email: {
            value: '',
            valid: true,
            // eslint-disable-next-line
            validator: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        password: {
            value: '',
            valid: true,
            validator: /.{8,}/
        }
    } as LoginForm);

    const onChange = (field: keyof LoginForm) => {
        return (e: any) => {
            var value = e.target.value;
            var valid = false;
            var validator = (form[field] as FormInput).validator;
            if ((form[field] as any).validator) {
                if ((form[field] as FormInput).validator.test(value)) {
                    valid = true;
                } else {
                    valid = false;
                }
            } else {
                if (value !== "") {
                    valid = true;
                } else {
                    valid = false;
                }
            }
            setForm({
                ...form, [field]: {
                    validator: validator,
                    valid: valid,
                    value: value
                }
            });
        }
    }

    const onSubmit = async () => {
        set_f_error(false);
        set_a_error(false);
        if(form.email.valid && form.password.valid
            && form.email.value.trim() !== '' && form.password.value.trim() !== '' ) {
                
                AuthService.login(form.email.value, form.password.value)
                .then(() => {
                    history.replace('/');
                })
                .catch(() => {
                    set_a_error(true);
                })
                
        } else {
            set_f_error(true)
        }
    }

    return (
        <>
            <Row className="abs-auth-row">
                <Col xl={{ span: 4, offset: 4 }} lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }} sm={{ span: 8, offset: 2 }} xs={12} className="align-self-center">
                    <div className="abs-auth-content">
                        <h1 className="abs-auth-header">Iniciar sesión</h1>
                        {f_error && <h3 className="abs-auth-error">Rellena todos los campos para continuar.</h3>}
                        {a_error && <h3 className="abs-auth-error">El correo y/o contraseña son incorrectos.</h3>}
                        <div className="abs-auth-spacer"></div>
                        <div className="abs-auth-spacer"></div>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className="abs-auth-label">Correo electrónico</Form.Label>
                                <Form.Control type="email" placeholder="Correo electrónico"
                                className={ `abs-auth-control ${form.email.valid ? '' : 'invalid-field'}` }
                                onChange={onChange("email")}  />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label className="abs-auth-label">Contraseña</Form.Label>
                                <Form.Control type="password" placeholder="Contraseña"
                                className={ `abs-auth-control ${form.password.valid ? '' : 'invalid-field'}` }
                                onChange={onChange("password")}  />
                            </Form.Group>
                            <div>
                                <Link to="/auth/signup">
                                    <Button className="abs-auth-button-secondary" type="submit">
                                        ¿Aún no tienes cuenta? Registrate
                                    </Button>
                                </Link>
                            </div>
                            <div className="abs-auth-spacer"></div>
                            <div className="abs-auth-spacer"></div>
                        </Form>
                            <Button className="abs-auth-button" onClick={onSubmit}>
                                Iniciar sesión
                            </Button>
                        <div className="abs-auth-spacer"></div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Login;
