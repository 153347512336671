import React from "react";
import { Route, Switch } from "react-router-dom";
import { EditNote, EditProject, NewNote, NewProject, Notes, Projects } from "../Components/Home";
import Error from "../Components/Home/Error";

const HomeRoutes = () => {
    return (
        <Switch>
            <Route path="/project/:pid/new-note" component={NewNote} />
            <Route path="/project/:pid/edit-note/:nid" component={EditNote} />
            <Route path="/project/:pid" component={Notes} />
            <Route path="/edit-project/:pid" component={EditProject} />
            <Route path="/new-project" component={NewProject} />
            <Route path="/error" component={Error} />
            <Route path="/" component={Projects} />
        </Switch>
    );
}

export default HomeRoutes;