import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import AuthService from "../../Service/AuthService";
import './AuthShell.scss';

interface FormInput {
    value: string,
    valid: boolean,
    validator: RegExp
}

interface SignupForm {
    name: FormInput,
    lastName: FormInput,
    email: FormInput,
    emailConfirmation: FormInput,
    password: FormInput,
    passwordConfirmation: FormInput,
}

const Signup = () => {

    const [u_error, set_u_error] = useState(false);
    const [f_error, set_f_error] = useState(false);
    const [e_error, set_e_error] = useState(false);
    const [p_error, set_p_error] = useState(false);
    const history = useHistory();

    const [form, setForm] = useState({
        name: {
            value: '',
            valid: true,
            validator: /.{3,}/
        },
        lastName: {
            value: '',
            valid: true,
            validator: /.{3,}/
        },
        email: {
            value: '',
            valid: true,
            // eslint-disable-next-line
            validator: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        emailConfirmation: {
            value: '',
            valid: true,
            // eslint-disable-next-line
            validator: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        password: {
            value: '',
            valid: true,
            validator: /.{8,}/
        },
        passwordConfirmation: {
            value: '',
            valid: true,
            validator: /.{8,}/
        },
    } as SignupForm);

    const onChange = (field: keyof SignupForm) => {
        return (e: any) => {
            var value = e.target.value;
            var valid = false;
            var validator = (form[field] as FormInput).validator;
            if ((form[field] as any).validator) {
                if ((form[field] as FormInput).validator.test(value)) {
                    valid = true;
                } else {
                    valid = false;
                }
            } else {
                if (value !== "") {
                    valid = true;
                } else {
                    valid = false;
                }
            }
            setForm({
                ...form, [field]: {
                    validator: validator,
                    valid: valid,
                    value: value
                }
            });
        }
    }

    const onSubmit = async () => {
        set_e_error(false);
        set_f_error(false);
        set_p_error(false);
        set_u_error(false);
        if(form.name.valid && form.lastName.valid
            && form.email.valid && form.emailConfirmation.valid
            && form.password.valid && form.passwordConfirmation.valid 
            && form.name.value.trim() !== '' && form.lastName.value.trim() !== ''
            && form.email.value.trim() !== '' && form.emailConfirmation.value.trim() !== ''
            && form.password.value.trim() !== '' && form.passwordConfirmation.value.trim() !== '') {
                if(!(form.passwordConfirmation.value === form.password.value)) {
                    set_p_error(true);
                    return;
                }
                if(!(form.emailConfirmation.value === form.email.value)) {
                    set_e_error(true);
                    return;
                }
                AuthService.signup(form.name.value, form.lastName.value, form.email.value, form.password.value)
                .catch(() => {
                    set_u_error(true);
                })
                .then(() => {
                    return AuthService.login(form.email.value, form.password.value);
                })
                .then(() => {
                    history.replace('/');
                })
                .catch(() => {
                    history.replace('/auth/login');
                })
        } else {
            set_f_error(true)
        }
    }

    return (
        <>
            <Row className="abs-auth-row">
                <Col xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} sm={{ span: 8, offset: 2 }} xs={12} className="align-self-center">
                    <div className="abs-auth-content">
                        <div className="abs-auth-spacer"></div>
                        <h1 className="abs-auth-header">Crear cuenta</h1>

                        {u_error && <h3 className="abs-auth-error">Ha ocurrido un error inesperado. Intenta de nuevo más tarde.</h3>}
                        {f_error && <h3 className="abs-auth-error">Rellena todos los campos para continuar.</h3>}
                        {e_error && <h3 className="abs-auth-error">Los correos electrónicos no coinciden.</h3>}
                        {p_error && <h3 className="abs-auth-error">Las contraseñas no coinciden.</h3>}
                        <div className="abs-auth-spacer"></div>
                        <div className="abs-auth-spacer"></div>
                        <Form>
                            <Row>
                                <Col md={6} sm={12}>
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label className="abs-auth-label">Nombre</Form.Label>
                                        <Form.Control type="text" placeholder="Nombre" 
                                        className={ `abs-auth-control ${form.name.valid ? '' : 'invalid-field'}` }
                                        onChange={onChange("name")} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Form.Group controlId="formBasicLasName">
                                        <Form.Label className="abs-auth-label">Apellido</Form.Label>
                                        <Form.Control type="text" placeholder="Apellido" 
                                        className={ `abs-auth-control ${form.lastName.valid ? '' : 'invalid-field'}` }
                                        onChange={onChange("lastName")} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label className="abs-auth-label">Correo electrónico</Form.Label>
                                        <Form.Control type="email" placeholder="Correo electrónico" 
                                        className={ `abs-auth-control ${form.email.valid ? '' : 'invalid-field'}` }
                                        onChange={onChange("email")} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label className="abs-auth-label">Confirmar correo electrónico</Form.Label>
                                        <Form.Control type="email" placeholder="Correo electrónico" 
                                        className={ `abs-auth-control ${form.emailConfirmation.valid ? '' : 'invalid-field'}` }
                                        onChange={onChange("emailConfirmation")} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label className="abs-auth-label">Contraseña</Form.Label>
                                        <Form.Control name="password" type="password" placeholder="Contraseña" 
                                        className={ `abs-auth-control ${form.password.valid ? '' : 'invalid-field'}` }
                                        onChange={onChange("password")} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label className="abs-auth-label">Confirmar contraseña</Form.Label>
                                        <Form.Control type="password" placeholder="Confirmar contraseña" 
                                        className={ `abs-auth-control ${form.passwordConfirmation.valid ? '' : 'invalid-field'}` }
                                        onChange={onChange("passwordConfirmation")} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                            <div>
                                <Link to="/auth/login">
                                    <Button className="abs-auth-button-secondary" type="submit">
                                        ¿Ya tienes cuenta? Inicia sesión
                                    </Button>
                                </Link>
                            </div>
                            <div className="abs-auth-spacer"></div>
                            <div className="abs-auth-spacer"></div>
                        </Form>
                            <Button className="abs-auth-button" type="submit" onClick={onSubmit}>
                                Crear cuenta
                                    </Button>
                        <div className="abs-auth-spacer"></div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Signup;
