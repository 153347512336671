import React, { useRef, useState } from "react";
import { Card, Container, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import ProjectService from "../../Service/ProjectService";
import './Home.scss';

interface FormInput {
    value: string,
    valid: boolean,
    validator: RegExp
}

interface NewProjectForm {
    title: FormInput
}

const NewProject = () => {
    
    const [f_error, set_f_error] = useState(false);
    const history = useHistory();
    let titleInput = useRef(null as HTMLInputElement);

    const [form, setForm] = useState({
        title: {
            value: '',
            valid: true,
            validator: /.{3,}/
        }
    } as NewProjectForm);

    const onChange = (field: keyof NewProjectForm) => {
        return (e: any) => {
            var value = e.target.value;
            var valid = false;
            var validator = (form[field] as FormInput).validator;
            if ((form[field] as any).validator) {
                if ((form[field] as FormInput).validator.test(value)) {
                    valid = true;
                } else {
                    valid = false;
                }
            } else {
                if (value !== "") {
                    valid = true;
                } else {
                    valid = false;
                }
            }
            setForm({
                ...form, [field]: {
                    validator: validator,
                    valid: valid,
                    value: value
                }
            });
        }
    }

    const newP = () => {
        set_f_error(false);
        if(form.title.valid
            && form.title.value.trim() !== '') {
                
                ProjectService.createProject(form.title.value)
                .then(() => {
                    history.replace('/');
                })
                .catch(() => {
                    history.replace('/error');
                })

        } else {
            set_f_error(true)
        }
    };

    return (
        <>
            <Card style={{
                padding: '20px'
            }}>
                <Container>
                    <h2 style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        marginTop: '10px'
                    }}>Nuevo Proyecto</h2>
                    {f_error && <h3 className="abs-auth-error">Rellena todos los campos para continuar.</h3>}
                    <Form>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Titulo</Form.Label>
                            <Form.Control type="text" 
                            ref={titleInput} 
                            className={ `abs-auth-control ${form.title.valid ? '' : 'invalid-field'}` }
                            onChange={onChange("title")}/>
                        </Form.Group>
                    </Form>
                        <Button className="abs-auth-button" style={{
                            marginBottom: '10px'
                        }}
                        onClick={newP}>
                            Añadir
                        </Button>
                </Container>
            </Card>
        </>
    );
}

export default NewProject;