import axios from "axios";
import { decode } from "jsonwebtoken";
import { apiURL } from "../env";

class AuthService {

    signup(name: string, lastName: string, email: string, password: string): Promise<void> {
        return new Promise((resolve, reject) => {
            axios.post(apiURL + '/user', {
                name: name,
                lastName: lastName,
                email: email,
                password: password
            }).then(() => {
                resolve();
            }).catch(() => {
                reject();
            });
        });
    }
    
    login(email: string, password: string) : Promise<void> {
        return new Promise((resolve, reject) => {
            axios.post(apiURL + '/user/login', {
                email: email,
                password: password
            }).then(data => {
                console.log(data.data.authToken);
                localStorage.setItem('auth', data.data.authToken);
                resolve();
            }).catch(() => {
                reject();
            });
        });
    }

    logout() : Promise<void> {
        return new Promise((resolve, reject) => {
            var token = localStorage.getItem("auth");
            if(!token) {
                resolve();
            }
            axios.post(apiURL + '/user/logout', {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(() => {
                localStorage.removeItem('auth');
                resolve();
            }).catch(() => {
                localStorage.removeItem('auth');
                resolve();
            });
        })
    }

    isloggedin() : boolean {
        var token = localStorage.getItem("auth");
        if(token) {
            var decoded = decode(token);
            if (Date.now() >= decoded['exp'] * 1000) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    getUserToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            var token = localStorage.getItem("auth");
            if(token) {
                resolve(token);
            } else {
                reject();
            }
        });
    }
}

export default new AuthService();