import axios from "axios";
import { apiURL } from "../env";
import Note from "../Models/note";
import Project from "../Models/project";
import AuthService from "./AuthService";

class ProjectService {

    getProjects() : Promise<Project[]> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.get(apiURL + '/project', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(projects => {
                    var p = projects.data as Project[];
                    resolve(p);
                })
                .catch(() => {
                    reject();
                })
            })
        });
    }

    getProject(id:number) : Promise<Project> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.get(apiURL + `/project/${id}`, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(project => {
                    var p = project.data as Project;
                    resolve(p);
                })
                .catch(() => {
                    reject();
                })
            })
        });
    }

    getProjectNotes(id:number) : Promise<Note[]> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.get(apiURL + `/project/${id}/notes`, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(project => {
                    var p = project.data as Note[];
                    resolve(p);
                })
                .catch(() => {
                    reject();
                })
            })
        });
    }

    createProject(title: string) : Promise<void> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.post(apiURL + `/project`, {
                    title: title
                }, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
            })
        });
    }

    updateProject(id: number, title: string) : Promise<void> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.post(apiURL + `/project`, {
                    id: id,
                    title: title
                }, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
            })
        });
    }

    deleteProject(id: number) : Promise<void> {
        return new Promise((resolve, reject) => {
            AuthService.getUserToken()
            .then(token => {
                axios.delete(apiURL + `/project/${id}`, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                })
            })
        });
    }
    
}

export default new ProjectService();