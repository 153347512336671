import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Project from "../../Models/project";
import ProjectService from "../../Service/ProjectService";
import './Home.scss';

const Projects = () => {

    let history = useHistory();
    const [projects, setProjects] = useState([] as Project[]);

    useEffect(() => {
        ProjectService.getProjects()
        .then(pr => {
            setProjects(pr);
        })
        .catch(() => {
            history.replace('/error');
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toProjectPage = (id: number) => {
        return () => {
            history.push(`/project/${id}`)
        }
    } 

    const toEditPage = (id: number) => {
        return (e:any) => {
            e.stopPropagation()
            history.push(`/edit-project/${id}`)
        }
    }

    const toDelete = (id: number) => {
        return (e: any) => {
            e.stopPropagation()
            ProjectService.deleteProject(id)
            .then(() => {
                var pr = [] as Project[];
                for(var i = 0; i < projects.length; i++) {
                    if(projects[i].id !== id) {
                        pr.push(projects[i]);
                    }
                }
                setProjects(pr);
            })
            .catch(() => {
                history.replace('/error');
            })
        }
    }

    const newPr = (e: any) => {
        history.push('/new-project');
        e.stopPropagation()
    }

    return (
        <>
            <Row>
                {
                    projects.map(p => {
                        return (
                            <Col xl={3} lg={3} md={6} sm={6} xs={12} className="card-margin" key={p.id}>
                                <Card className="card-hover" onClick={toProjectPage(p.id)}>
                                    <Card.Body style={{ textAlign: 'center' }}>
                                        <Card.Title>{p.title}</Card.Title>
                                        <FontAwesomeIcon icon={faEdit} className="icon" onClick={toEditPage(p.id)}/>
                                        <FontAwesomeIcon icon={faTrashAlt} className="icon" onClick={toDelete(p.id)}/>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }

                <Col xl={3} lg={3} md={6} sm={6} xs={12} className="card-margin">
                    <Card className="card-hover new-project" onClick={newPr}>
                        <Card.Body>
                            <Card.Title style={{ textAlign: 'center' }}>+ New Project<br/></Card.Title>
                        </Card.Body>
                    </Card>
                </Col>

                
            </Row>
        </>
    );
}

export default Projects;