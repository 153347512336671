import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthShell from "../Components/Auth/AuthShell";
import { Home } from "../Components/Home";
import { ScrollToTop } from "../Components/Shared";
import { Unauthorized } from "../Components/Unauthorized";

const Routes = () => {
    return (
        <Router>
            <ScrollToTop>
                <Switch>
                    <Route path="/unauthorized" component={ Unauthorized }/>
                    <Route path="/auth" component={ AuthShell }/>
                    <Route path="/" component={ Home }/>
                </Switch>
            </ScrollToTop>
        </Router>
    );
}

export default Routes;
