import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import AuthService from "../../Service/AuthService";
import './AuthShell.scss';

const Logout = () => {

    const history = useHistory();

    useEffect(() => {
        AuthService.logout().then(() => {
            history.push('/auth/login')
        });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Row className="abs-auth-row">
                <Col xl={{ span: 4, offset: 4 }} lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }} sm={{ span: 8, offset: 2 }} xs={12} className="align-self-center">
                    <div className="abs-auth-content">
                        <div className="abs-auth-spacer"></div>
                        <div className="abs-auth-spacer"></div>
                        <h1 className="abs-auth-header">Cerrando sesión...</h1>
                        <div className="abs-auth-spacer"></div>
                        <div className="abs-auth-spacer"></div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Logout;
